import env from '../env.json';

const API = {
    HOST: env.API_HOST,
    PATH_COMMAND: "/api/commands",
    PATH_SUITE: "/api/suites",
    PATH_CATEGORY: "/api/categories",
    PATH_OS: "/api/os",
}

export default API;


