import axios from 'axios';
import api from '../config/api';
import SuiteType from '../types/suiteType';
import SuiteFactory from '../helpers/factories/suiteFactory';

async function search() {
    const url = api.HOST + api.PATH_SUITE;
    var suites:Array<SuiteType> = [];
    try {
        var response = await axios.get(url, { withCredentials: true });
        suites = response.data.suites;
    } catch (error: any) {
        suites = [];
    }
    return suites;
}

async function find(id: string) {
    const url = api.HOST + api.PATH_SUITE + "/" + id;
    var suite:SuiteType = SuiteFactory.create();
    try {
        var response = await axios.get(url, { withCredentials: true });
        suite = response.data.suite;
    } catch (error: any) {
        //
    }
    return suite;
}

export default { search, find };