import React, { useEffect, useState } from "react";
import Param from "../atoms/param";
import CommandType from "../../types/commandType";
import ParamType from "../../types/paramType";

interface ControlProps {
  commands: Array<CommandType>;
  onChange: (params: Array<ParamType>) => void;
}

const Control = (props: ControlProps) => {
  const [params, setParams] = useState(new Array<ParamType>);

  useEffect(() => {
    // dic to check if param already exists
    var dic: Array<string> = [];
    var generated_params: Array<ParamType> = [];

    // create params list from commands
    for (var command of props.commands) {
      for (var param of command.params) {
        // skip if param already exists
        if (dic.includes(param.name)) {
          continue;
        }
        // no update if param already exists
        var old_param = params.find((ele, key) => {
          if (ele.name === param.name) {
            return true;
          }
        });
        if (old_param !== undefined) {
          param = old_param;
        }
        dic.push(param.name);
        generated_params.push(param);
      }
    }
    setParams(generated_params);
    // propagate to parent
    props.onChange(generated_params);
  }, [props.commands]);
  
  // param children values changed
  const onParamChange = (index: number, param: ParamType) => {
    var updated_params = [...params];
    updated_params[index] = param;
    setParams(updated_params);
    // propagate to parent
    props.onChange(updated_params);
  }

  return (
    <div className="control">
      <div className="control_elements">
        <div className="form">
          { 
            params.map((param: ParamType, i) => 
              <Param key={i} index={i} param={param} onChange={onParamChange} />
            )
          }
        </div>
      </div>
    </div>
  );
}

export default Control;