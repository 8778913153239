import React, { useRef, useContext } from "react";
import utils from "../../helpers/utils";

interface DownloadProps {
  filename: string;
  contents: string;
}
const Download = (props: DownloadProps) => {
  // icons
  const dl = useRef<HTMLDivElement>(null);
  const checked = useRef<HTMLDivElement>(null);
  const download = useRef<HTMLAnchorElement>(null);
  // toggle icons
  function toggleNodes() {
      const dlNode = dl.current;
      const checkedNode = checked.current;
      utils.toggle(dlNode);
      utils.toggle(checkedNode);
  }
  // download script
  function onDownloadClick(){
    toggleNodes();
    setTimeout(() => toggleNodes(), 2000);
    const blob = new Blob([props.contents], {type: 'text/plain;charset=utf-8'});
    const url = URL.createObjectURL(blob);

    const downloadNode = download.current;
    if (downloadNode === null) {
      return;
    }
    downloadNode.href = url;
    downloadNode.download = props.filename;
    downloadNode.click();
  }

  return (
    <div className="download_wrap" onClick={onDownloadClick}>
      {/* download */}
      <div className="download" ref={dl}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
          <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z" />
          <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
        </svg>
      </div>
      <div className="download download_checked hidden" ref={checked}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-file-earmark-check-fill" viewBox="0 0 16 16">
          <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm1.354 4.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
        </svg>
      </div>
      <a href="" className="blob_download" ref={download} onClick={utils.stopPropagate()} />
    </div>
  );
}

export default Download;