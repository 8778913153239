import { MouseEvent } from "react";
import system from "../config/system";

function toggle(node: HTMLDivElement|null) {
    if (node === null) {
        return;
    }
    var hidden = 'hidden';
    if (node.classList.contains(hidden)) {
        node.classList.remove(hidden);
    } else {
        node.classList.add(hidden);
    }
}

function propagate(id: string) {
    document.getElementById(id)!.click();
}

function stopPropagate() {
    return (e: MouseEvent) => { 
        e.stopPropagation(); 
    };
}

function stopPropagateAnd(func: Function) {
    return (e: MouseEvent) => {
        e.stopPropagation();
        func();
    }
}

function getScriptName(ext: string) {
    var name = system.SCRIPT_PREFIX + "_" + Date.now() + "." + ext;
    return name;
}

export default { toggle, propagate, stopPropagate, stopPropagateAnd, getScriptName };