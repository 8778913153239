import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import "./index.css";
import CommandList from "../../components/molecules/commandList";
import Dropdown from "../../components/atoms/dropdown";
import Control from "../../components/molecules/control";
import Download from "../../components/atoms/download";
import ParamType from "../../types/paramType";
import CommandType from "../../types/commandType";
import SuiteType from "../../types/suiteType";
import CategoryType from "../../types/categoryType";
import OsType from "../../types/osType";
import CommandService from "../../services/commandService";
import SuiteService from "../../services/suiteService";
import CategoryService from "../../services/categoryService";
import OsService from "../../services/osService";
import utils from "../../helpers/utils";
import SuiteFactory from "../../helpers/factories/suiteFactory";
import CategoryFactory from "../../helpers/factories/categoryFactory";
import OsFactory from "../../helpers/factories/osFactory";
import Markdown from "../../components/atoms/markdown";

const Command = () => {
  // define state
  const [suite, setSuite] = useState(SuiteFactory.create());
  const [categories, setCategories] = useState(new Array<CategoryType>);
  const [os_list, setOsList] = useState(new Array<OsType>);
  const [category, setCategory] = useState(CategoryFactory.create());
  const [os, setOs] = useState(OsFactory.create());
  const [base_commands, setBaseCommands] = useState(new Array<CommandType>);
  const [commands, setCommands] = useState(new Array<CommandType>);
  const [errors, setErrors] = useState([]);
  const [params, setParams] = useState(new Array<ParamType>);
  const [script, setScript] = useState("");
  const [markdown, setMarkdown] = useState("");

  // url params { suite }
  const props = useParams();
  const suite_id = props.suite ?? "";

  useEffect(() => {
    // get category list
    getCategory();
    // get os list
    getOs();
    // set suite param
    setSuite(SuiteFactory.create());
    if (props.suite !== undefined) {
      getSuite();
    }
    // get command list
    getCommands();
  }, [props]);

  useEffect(() => {
    var updated_commands = [...commands];
    for (var command of updated_commands) {
      // get initial command
      const base_command = base_commands.find((ele, key) => {
        if (ele.id === command.id) {
          return true;
        }
      });
      // command not found
      if (base_command === undefined) {
        continue;
      }
      var generated_payload = CommandService.generatePayload(base_command, params);
      var updated_command = structuredClone(command);
      updated_command.payload = generated_payload;

      updated_commands.find((ele, key) => {
        if (ele.id === updated_command.id) {
          updated_commands[key] = updated_command;
          return true;
        }
      });
    }
    setCommands(updated_commands);
  }, [params]);

  useEffect(() => {
    const updated_script = CommandService.generateScript(commands);
    setScript(updated_script);
    const updated_markdown = CommandService.generateMarkdown(commands);
    setMarkdown(updated_markdown);
  }, [commands, params])

  useEffect(() => {
    var updated_commands = [...commands];
    for (var index in updated_commands) {
      var command = updated_commands[index];
      command.meta.visible = true;
      // os filter
      if (os.id !== "") {
        var include_os = command.os.find((ele, key) => {
          if (ele.id === os.id) {
            return true;
          }
        });
        if ( include_os === undefined ) {
          command.meta.visible = false;
        } 
      }
      // category filter
      if (category.id !== "") {
        var include_category = command.category.find((ele, key) => {
          if (ele.id === category.id) {
            return true;
          }
        });
        if ( include_category === undefined ) {
          command.meta.visible = false;
        } 
      }
      updated_commands[index] = structuredClone(command);
    }
    setCommands(updated_commands);
  }, [os, category]);

  async function getSuite() {
    const suite: SuiteType = await SuiteService.find(suite_id);
    setSuite(suite);
  }

  async function getCategory() {
    const categories: Array<CategoryType> = await CategoryService.search();
    setCategories(categories);
  }

  async function getOs() {
    const os_list: Array<OsType> = await OsService.search();
    setOsList(os_list);
  }

  async function getCommands() {
    const command_list = await CommandService.search({ suite: suite_id });
    setBaseCommands(command_list);
    setCommands(command_list);
  }

  const onCommandsChange = (commands: Array<CommandType>) => {
    setCommands(commands);
  }

  const onParamsChange = (params: Array<ParamType>) => {
    setParams(params);
  }

  return (
    <div className="command">
      <h1></h1>
      <div className="command_action">
        <div className="action_wrap">
          <Dropdown placeholder="OS" options={os_list} onChange={setOs} />
          <Dropdown placeholder="Category" options={categories} onChange={setCategory} />
        </div>
        <div className="action_wrap">
          <Markdown filename={utils.getScriptName("md")} contents={markdown} />
          <Download filename={utils.getScriptName("sh")} contents={script} />
        </div>
      </div>
      <CommandList base_commands={ base_commands } commands={ commands } onChange={ onCommandsChange }/>
      <Control commands={ base_commands } onChange={ onParamsChange }/>
    </div>
  );
}

export default Command;
