import React, { useState, useEffect } from "react";
import CommandType from "../../types/commandType";
import ParamType from "../../types/paramType";
import CommandTile from "../atoms/commandTile";
import CommandService from "../../services/commandService";

interface CommandListProps {
  base_commands: Array<CommandType>;
  commands: Array<CommandType>;
  onChange: (commands: Array<CommandType>) => void;
}

const CommandList = (props: CommandListProps) => {
  const [commands, setCommands] = useState(props.commands);

  useEffect(() => {
    setCommands(props.commands);
  }, [props.commands]);

  const onCommandChange = (command: CommandType) => {
    var updated_commands = [...commands];
    updated_commands.find((ele, key) => {
      if (ele.id === command.id) {
        var updated_command = structuredClone(command);
        updated_commands[key] = updated_command;
        return true;
      }
    })
    setCommands(updated_commands);
    props.onChange(updated_commands);
  }

  return (
    <div className="command_list">
      {
        commands.length ?
        commands.map((command: CommandType, i) => <CommandTile key={i} command={command} onChange={ onCommandChange } />)
        : null
      }
    </div>
  );
}

export default CommandList;