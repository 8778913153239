
const SYSTEM = {
    VERSION: "0.1.0",
    LF: "\n",
    HTTP_STATUS: {
        SUCCESS: 200,
        NOT_AUTHORIZED: 403,
        NOT_FOUND: 404,
        NOT_VALID: 422
    },
    SCRIPT_PREFIX: "mairo",
};

export default SYSTEM;