import React from "react";
import "./index.css";

export default class NotFound extends React.Component {

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className="error">
        <h1>Not Found</h1>
        <div className="message">
          <p>Sorry, nothing here.</p>
        </div>
      </div>
    );
  };
}

