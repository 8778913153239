import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import CommandTile from "../../components/atoms/commandTile";
import Download from "../../components/atoms/download";
import Markdown from "../../components/atoms/markdown";
import CommandType from "../../types/commandType";
import OsType from "../../types/osType";
import CategoryType from "../../types/categoryType";
import CommandService from "../../services/commandService";
import CommandFactory from "../../helpers/factories/commandFactory";
import utils from "../../helpers/utils";
import OsFactory from "../../helpers/factories/osFactory";
import CategoryFactory from "../../helpers/factories/categoryFactory";

const Home = () => {
  const [command, setCommand] = useState<CommandType>(CommandFactory.create());
  const [script, setScript] = useState("");
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    const demo_os: OsType = OsFactory.create();
    demo_os.id = "1";
    demo_os.name = "Linux";
    demo_os.value = "linux";
    demo_os.color = "#FFA500";
    const demo_category: CategoryType = CategoryFactory.create();
    demo_category.id = "1";
    demo_category.name = "Exploit"
    demo_category.value = "exploit"
    const demo_command: CommandType = CommandFactory.create();
    demo_command.id = "1";
    demo_command.title = "NetCat TCP Listener";
    demo_command.os = [demo_os];
    demo_command.category = [demo_category];
    demo_command.payload = "nc -lnvp 443";
    setCommand(demo_command);
  }, []);

  useEffect(() => {
    const updated_script = CommandService.generateScript([command]);
    setScript(updated_script);
    const updated_markdown = CommandService.generateMarkdown([command]);
    setMarkdown(updated_markdown);
  }, [command])

  const onChange = (command: CommandType) => {
    setCommand(command);
  }

  return (
    <div className="home">
      <h1>Mairo</h1>
      <section className="home_section">
        <h2>Follow Mairo, and Find the Treasure</h2>
        <div className="home_content">
          <img className="logo" src="/logo.png" />
          <p className="message">
            You don't need to rewrite commands anymore! <br/>
            Just fill the form to generate commands specified for your target. 
          </p>
        </div>
        <div className="btn_wrap">
          <Link to="/commands" className="btn btn-primary">Try</Link>
        </div>
      </section>
      <section className="home_section">
        <h2>Generate, Copy and Hack It</h2>
        <div className="home_content">
          <p className="message">
            You can copy & paste generated commands easily.
            Do you want to automate? No worry, you can download script file!
          </p>
        </div>
        <CommandTile command={command} onChange={onChange}/>
        <p className="click_message">Click to Download Script!</p>
        <div>
          <Download filename={utils.getScriptName("sh")} contents={script} />
        </div>
      </section>
      <section className="home_section">
        <h2>Keep Track of Your Business</h2>
        <div className="home_content">
          <p className="message">
            Don't forget to take notes! It will save you later.
            On Mairo, you can take notes for your each attempt.
            And also, you can generate Markdown report!
          </p>
        </div>
        <p className="click_message">Click to Download Markdown Report!</p>
        <div>
          <Markdown filename={utils.getScriptName("md")} contents={markdown} />
        </div>
      </section>
    </div>
  );
}

export default Home;