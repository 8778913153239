import React, { useContext, ChangeEvent, useEffect, useState, ChangeEventHandler } from "react";
import ParamType from "../../types/paramType";


interface ParamProps {
  index: number;
  param: ParamType;
  onChange: (key: number, param: ParamType) => void;
}

const Param = (props: ParamProps) => {
  // param value state
  const [param, setParam] = useState(props.param);
  // update param value
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    var updated_param = param;
    updated_param.value = event.target.value ?? ""
    setParam(updated_param);
    props.onChange(props.index, updated_param);
  }

  return (
    <div className="param field">
      <label>{props.param.name}</label>
      <input type="text" id={props.param.name} value={param.value} onChange={onInputChange} />
    </div>
  );
}

export default Param;