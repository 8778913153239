import React, { useRef, useContext } from "react";
import utils from "../../helpers/utils";

interface MarkdownProps {
  filename: string;
  contents: string;
}
const Markdown = (props: MarkdownProps) => {
  // icons
  const dl = useRef<HTMLDivElement>(null);
  const checked = useRef<HTMLDivElement>(null);
  const download = useRef<HTMLAnchorElement>(null);
  // toggle icons
  function toggleNodes() {
      const dlNode = dl.current;
      const checkedNode = checked.current;
      utils.toggle(dlNode);
      utils.toggle(checkedNode);
  }
  // download script
  function onMarkdownClick(){
    toggleNodes();
    setTimeout(() => toggleNodes(), 2000);
    const blob = new Blob([props.contents], {type: 'text/plain;charset=utf-8'});
    const url = URL.createObjectURL(blob);

    const downloadNode = download.current;
    if (downloadNode === null) {
      return;
    }
    // remove blob object
    if (downloadNode.href !== "") {
      URL.revokeObjectURL(downloadNode.href);
    }
    downloadNode.href = url;
    downloadNode.download = props.filename;
    downloadNode.click();
  }

  return (
    <div className="markdown_wrap" onClick={onMarkdownClick}>
      {/* download */}
      <div className="markdown" ref={dl}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-markdown" viewBox="0 0 16 16">
          <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"/>
          <path fillRule="evenodd" d="M9.146 8.146a.5.5 0 0 1 .708 0L11.5 9.793l1.646-1.647a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 0-.708z"/>
          <path fillRule="evenodd" d="M11.5 5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 1 .5-.5z"/>
          <path d="M3.56 11V7.01h.056l1.428 3.239h.774l1.42-3.24h.056V11h1.073V5.001h-1.2l-1.71 3.894h-.039l-1.71-3.894H2.5V11h1.06z"/>
        </svg>
      </div>
      <div className="markdown markdown_checked hidden" ref={checked}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-markdown-fill" viewBox="0 0 16 16">
          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm11.5 1a.5.5 0 0 0-.5.5v3.793L9.854 8.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L12 9.293V5.5a.5.5 0 0 0-.5-.5zM3.56 7.01h.056l1.428 3.239h.774l1.42-3.24h.056V11h1.073V5.001h-1.2l-1.71 3.894h-.039l-1.71-3.894H2.5V11h1.06V7.01z"/>
        </svg>
      </div>
      <a href="" className="blob_download" ref={download} onClick={utils.stopPropagate()}/>
    </div>
  );
}

export default Markdown;