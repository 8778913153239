import React, { useRef, useState, useEffect, ChangeEvent } from "react";
import CommandType from "../../types/commandType";
import Clipboard from "./clipboard";
import Caret from "./caret";
import utils from "../../helpers/utils";
import commandFactory from "../../helpers/factories/commandFactory";
import OsType from "../../types/osType";
import CategoryType from "../../types/categoryType";

interface CommandProps {
  command: CommandType;
  onChange: (command: CommandType) => void;
}

const CommandTile = (props: CommandProps) => {
  // define states
  const [command, setCommand] = useState<CommandType>(commandFactory.create());

  // icon control
  const clip_id = "clipboard" + props.command.id;
  const caret_id = "caret" + props.command.id;
  const noteRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    setCommand(props.command);
  }, [props.command]);

  // update payload
  const onPayloadChange = (event:ChangeEvent<HTMLInputElement>)  => {
    var updated_command = structuredClone(command);
    updated_command.payload = event.target.value;
    setCommand(updated_command);
    props.onChange(updated_command);
  }

  //update note
  const onNoteChange = (event:ChangeEvent<HTMLTextAreaElement>) => {
    var updated_command = structuredClone(command);
    updated_command.note = event.target.value;
    setCommand(updated_command);
    props.onChange(updated_command);
  }

  // toggle icons
  const toggleNote = () => {
    const noteNode = noteRef.current;
    utils.toggle(noteNode);
    utils.propagate(caret_id);
  }

  return (
    <div className={ "command_tile" + (command.meta.visible ? "" : " hidden")}>
      <div className="command_header">
        <span className="title">{command.title}</span>
        { command.os.length ? 
          command.os.map((os: OsType, i) => 
            (<span key={i} className="os badge badge-primary" style={{color: os.color, borderColor: os.color}}>{os.value}</span>)
          )
          : ""
        }
        { command.category.length ? 
          command.category.map((category: CategoryType, i) => 
            <span key={i} className="category badge badge-secondary">{category.value}</span>
          )
          : "" 
        }
      </div>
      <div className="command_body">
        <input type="checkbox" name="{command.id}" className="check" />
        <input className="payload" value={command.payload} onChange={onPayloadChange}/>
        <Clipboard id={clip_id} text={command.payload} />
      </div>
      <div className="command_footer">
        <div className="note_wrap hidden" ref={noteRef}>
          <textarea className="note_area" value={command.note} onChange={onNoteChange}></textarea>
        </div>
        <div className="note_toggle" onClick={toggleNote}>
          <Caret id={caret_id} />
        </div>
      </div>
    </div>
  );
}

export default CommandTile;