import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import SuiteType from "../../types/suiteType";

interface SuiteProps {
  suite: SuiteType;
  selected: boolean;
}

const SuiteTile = (props: SuiteProps) => {
  // param value state
  const [suite, setSuite] = useState(props.suite);
  const elementRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const path = "/commands/" + suite.id;

  useEffect(() => {
    const elementNode = elementRef.current;
    if (elementNode === null) {
      return;
    }

    if (location.pathname === path) {
      elementNode.classList.add("selected");
    } else {
      elementNode.classList.remove("selected");
    }
  }, [location, props.suite]);

  return (
    <div className="element" ref={elementRef}>
      <Link to={ path }>
        {suite.title}
      </Link>
    </div>
  );
}

export default SuiteTile;