import React from "react";
import { Link } from 'react-router-dom';
import system from "../../config/system";

export default function Footer(props:any) {
  return (
  <div className="footer">
    <div className="copyright">
      <p>Mairo { system.VERSION }</p>
      <p>© tkusa 2023. All rights reserved.</p>
    </div>
  </div>
  );
    
}