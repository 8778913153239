import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css';
import Header from './components/molecules/header';
import Footer from './components/molecules/footer';
import Sidebar from './components/molecules/sidebar';
import Tab from './components/molecules/tab';
import Home from './pages/home';
import Command from './pages/command';
import NotFound from './pages/error/not_found';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <div className="page">
          <Sidebar />
          <Tab />
          <div className="contents">
            <Routes>
              <Route path='/' element={<Home />} />  
              <Route path='/commands/:suite?' element={<Command />} /> 
              <Route path='/*' element={<NotFound/>} /> 
            </Routes>
          </div>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
