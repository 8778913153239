import axios from 'axios';
import api from '../config/api';
import CategoryType from '../types/categoryType';

async function search() {
    const url = api.HOST + api.PATH_CATEGORY;
    var categories:Array<CategoryType> = [];
    try {
        var response = await axios.get(url, { withCredentials: true });
        categories = response.data.categories;
    } catch (error: any) {
        categories = [];
    }
    return categories;
}

export default { search };