import CommandType from "../../types/commandType";


function create(): CommandType {
  var data = {
    id: "",
    title: "",
    os: [],
    protocol: "",
    category: [],
    tactic: "",
    technique: "",
    payload: "",
    params: [],
    note: "",
    meta: {
      visible: true
    }, 
  }
  return data;
}

export default { create };