import React, { ChangeEvent } from "react";
import OptionFactory from "../../helpers/factories/optionFactory";
import OptionType from "../../types/optionType";

interface DropDownProps {
  placeholder: string;
  options: Array<OptionType>;
  onChange: (value: OptionType) => void;
}

const Dropdown = (props: DropDownProps) => {

  const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    var option = props.options.find((ele, key) => {
      if (ele.id === e.target.value) {
        return true;
      }
    });
    if (e.target.value === "") {
      // empty option
      option = OptionFactory.create();
    }
    if (option === undefined) {
      return;
    }
    props.onChange(option);
  }

  return (
    <div className="dropdown_wrap">
      <select className="dropdown" onChange={ onSelectChange }>
        <option className="dropdown_placeholder" value="">{ props.placeholder }</option>
        {props.options.map((option: OptionType, i) => <option key={i} value={option.id}>{option.name}</option>)}
      </select>
    </div>
  );
}

export default Dropdown;