import OptionType from "../../types/optionType";


function create(): OptionType {
  var data = {
    id: "",
    name: "",
    value: "",
    color: ""
  }
  return data;
}

export default { create };