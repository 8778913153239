import CategoryType from "../../types/categoryType";


function create(): CategoryType {
  var data = {
    id: "",
    name: "",
    value: "",
    color: ""
  }
  return data;
}

export default { create };