import axios from 'axios';
import api from '../config/api';
import OsType from '../types/osType';

async function search() {
    const url = api.HOST + api.PATH_OS;
    var os:Array<OsType> = [];
    try {
        var response = await axios.get(url, { withCredentials: true });
        os = response.data.os;
    } catch (error: any) {
        os = [];
    }
    return os;
}

export default { search };