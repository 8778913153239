import SuiteType from "../../types/suiteType";


function create(): SuiteType {
  var data = {
    id: "",
    title: "",
    commands: [],
    description: ""
  }
  return data;
}

export default { create };