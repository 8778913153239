import axios from 'axios';
import api from '../config/api';
import CommandType from '../types/commandType';
import ParamType from '../types/paramType';
import system from '../config/system';

async function search(query: { suite: string }) {
  var url = api.HOST + api.PATH_COMMAND + "/" + query.suite;
  var commands: Array<CommandType> = [];
  try {
    var response = await axios.get(url, { withCredentials: true });
    var command_list = response.data.commands;
    for (var command of command_list) {
      command.meta = {
        visible: true
      };
      commands.push(command);
    }
  } catch (error: any) {
    commands = [];
  }
  return commands;
}

function generatePayload(command: CommandType, params: Array<ParamType>) {
  var tmp_command = structuredClone(command);
  var generated_payload = tmp_command.payload;
  for (var param of tmp_command.params) {
    const p = params.find(ele => ele.name === param.name);
    if (p === undefined) {
      continue;
    }
    const replace = "<" + param.name + ">";
    generated_payload = generated_payload.replaceAll(replace, p.value ?? "");
  }
  return generated_payload;
}

function generateScript(commands: Array<CommandType>) {
  var script = "";
  script += "#!/bin/sh" + system.LF;

  for (var command of commands) {
    if ( ! command.meta.visible) {
      continue;
    }
    script += "echo '[*] " + command.title + "'" + system.LF;
    script += command.payload + system.LF;
    script += "echo '[+] " + command.title + " done!'" + system.LF;
    script += system.LF;
  }

  return script;
}

function generateMarkdown(commands: Array<CommandType>) {
  var md = "";
  var date = new Date();
  md += "# Mairo Report " + date.toISOString() + system.LF;
  md += system.LF;
  for (var command of commands) {
    if ( ! command.meta.visible) {
      continue;
    }
    md += "## " + command.title + system.LF;
    md += system.LF;
    md += "```" + system.LF;
    md += command.payload + system.LF;
    md += system.LF;
    md += command.note + system.LF;
    md += "```" + system.LF;
    md += system.LF + system.LF;
  }

  return md;
}

export default { search, generatePayload, generateScript, generateMarkdown };