import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import SuiteService from '../../services/suiteService';
import SuiteType from '../../types/suiteType';
import SuiteTile from "../atoms/suiteTile";

export default function Sidebar(props: any) {

  const [suites, setSuites] = useState(new Array<SuiteType>());
  const [errors, setErrors] = useState([]);
  const location = useLocation();
  useEffect(() => {
    getSuites();
  }, []);

  async function getSuites() {
    const suites: Array<SuiteType> = await SuiteService.search();
    setSuites(suites);
  }

  return (
  <div className="sidebar no_sp">
    <div className="sidebar_elements">
      <div className={ "element" + (location.pathname === "/commands" ? " selected" : "")}>
          <Link to="/commands">
            All
          </Link>
      </div>
      {
        suites.length ?
        suites.map((suite: SuiteType, i) => 
          <SuiteTile key={i} suite={suite} selected={null === suite.id}/>)
        : null
      }
    </div>
  </div>
  );
    
}