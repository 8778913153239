import React from "react";
import { Link } from 'react-router-dom';

export default function Header(props:any) {
  return (
  <div className="header">
    <div className="header_left">
      <a href="/">
        <img className="logo" src="/logo.png" />
      </a>
      <div className="app_name">
        <span>Mairo</span>
      </div>
    </div>
    <div className="header_right">
      <div className="header_icon">
      </div>
    </div>
  </div>
  );
    
}